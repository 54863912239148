<template>  
  <div class="page-box">
    <div class="search-box">
      <div class="custom-box rowbetween">  
        <div class="s-store rowCenter" @click="showStore = true">
          <img src="../assets/images/local.png" class="local-icon" mode="aspectFill" />
          <span class="store-name" :class="{'active':(queryParams.shopId!=null&&queryParams.shopId!='0')}">{{queryParams.shopName?queryParams.shopName:'请选择门店'}}</span>
          <img src="../assets/images/arrow2.png" class="arrow-icon" mode="aspectFill" />
        </div>       
        <van-popup v-model:show="showStore" round position="bottom">
          <van-picker
            :columns="storeList"
            @cancel="showStore = false"
            @confirm="onConfirmStore"
          />
        </van-popup>  
        <div class="s-store rowCenter" @click="goFilter">
          <img src="../assets/images/screen.png" class="screen-icon" mode="aspectFill" />
          <span class="custom-name">自定义查询</span>
        </div>
      </div>
      <div class="search-in rowbetween">
        <div class="select-box rowbetween" @click="showStatus = true">  
          <span class="select-name" :class="{'active':(queryParams.payStatus!=null&&queryParams.payStatus!='0')}">{{queryParams.payStatusName?queryParams.payStatusName:'选择交易状态'}}</span>
          <img src="../assets/images/arrow2.png" class="arrow-icon" mode="aspectFill" />
        </div>         
        <van-popup v-model:show="showStatus" round position="bottom">
          <van-picker
            :columns="PayStatusList"
            @cancel="showStatus = false"
            @confirm="onConfirmStatus"
          />
        </van-popup>
        <div class="select-box rowbetween" @click="showTime = true">
          <span class="select-name" :class="{'active':queryParams.startTime!=null}">{{queryParams.startTime?queryParams.startTime.split(' ')[0]:'选择时间'}}</span>
          <img src="../assets/images/arrow2.png" class="arrow-icon" mode="aspectFill" />
        </div>      
        <van-popup v-model:show="showTime" round position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="date"
            title="选择年月日"
            :min-date="minDate"
            @cancel="showTime = false"
            @confirm="onConfirmsTime"
          />
        </van-popup>
      </div>
    </div>
    <div class="searchBack"></div>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="order-list-refresh">
        <van-list
          class="order-flow"
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          @offset="10"
        >
          <div class="flow-item" v-for="(item,index) in list" :key="index">
            <div class="flow-summary" @click="toggleDetails(item,index)">
              <div class="summary-box">
                <div class="summary-time row">
                  <span>{{item.day?item.day.split(' ')[0]:''}}</span>                  
                  <van-loading type="spinner" v-show="item.loading==true" size="14px" class="loading-icon" />
                </div>
                <div class="summary-info rowCenter">
                  <div class="value-item">
                    交易笔数：<span class="s-value">{{item.orderCount}}</span>
                  </div>
                  <div class="value-item">
                    结算金额：<span class="s-value">{{item.orderAmount}}</span>
                  </div>
                </div>
              </div>
              <img src="../assets/images/arrow3.png" class="arrow3-icon" :class="{'open':item.show==true}" mode="aspectFill" />
            </div>
            <div class="flow-detail" v-show="item.show==true">
              <div class="detail-item rowbetween" v-for="(child,numb) in item.tradeList" :key="numb">
                <div class="pay-type rowCenter">
                  <img v-if="child.payType == 7" src="../assets/images/wx.png" class="pay-icon" mode="aspectFill" />
                  <img v-if="child.payType == 8" src="../assets/images/zfb.png" class="pay-icon" mode="aspectFill" />
                  <img v-if="child.payType == 9" src="../assets/images/ysf.png" class="pay-icon" mode="aspectFill" />
                  <img v-if="child.payType == 10" src="../assets/images/yzf.png" class="pay-icon" mode="aspectFill" />
                  <div class="pay-info column">
                    <span class="type-name">{{child.merOrderNo}}</span>
                    <span class="pay-time">{{child.createTime?child.createTime.split(' ')[1]:''}}</span>
                  </div>
                </div>
                <div class="money-info column">
                  <span class="pay-money">￥{{child.orderAmount}}</span>
                  <span class="pay-status" v-if="child.payStatus==1">处理中</span>
                  <span class="pay-status" v-if="child.payStatus==2">下单成功</span>
                  <span class="pay-status pay-fail" v-if="child.payStatus==3">下单失败</span>
                  <span class="pay-status" v-if="child.payStatus==4">支付成功</span>
                  <span class="pay-status pay-fail" v-if="child.payStatus==5">支付失败</span>
                  <span class="pay-status pay-fail" v-if="child.payStatus==6">已支付/已退款</span>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
  import { reactive, onMounted, toRefs, nextTick } from 'vue'
  import { useStore  } from 'vuex'
  import { useRouter } from 'vue-router'
  import { getStoreListApi } from '@/api/store'
  import { getOrderFlowApi, getFlowDetailApi } from '@/api/orderflow'
  import { parseTime } from '@/utils/index'
  import { Toast } from 'vant'
  export default {
    name: 'orderflow1',
    setup() {
      const router = useRouter();
      const store = useStore()
      const state = reactive({
        loading: false,
        finished: false,
        refreshing: false,
        totalPage:0,
        list: [
          // {
          //   day:'2022-06-25',
          //   orderCount:183,
          //   orderAmount:2835.12,
          //   tradeList:[
          //     {
          //       merOrderNo: '4354535', //商户订单号
          //       payType: 7,  //交易方式 7=微信支付、8=支付宝支付、9=云闪付支付
          //       settAmount:156.12,
          //       payStatus:1,   //1=处理中 2=下单成功 3=下单失败 4=支付成功 5=支付失败 6=已退款
          //       payTime:'2022-06-25 10:23:42',
          //     },
          //     {
          //       merOrderNo: '4354535', //商户订单号
          //       payType: 8,  //交易方式 1支付宝 2微信 3云闪付
          //       settAmount:156.12,
          //       payStatus:2, 
          //       payTime:'2022-06-25 10:23:42',
          //     }
          //   ]
          // },
          // {
          //   day:'2022-06-25',
          //   orderCount:183,
          //   orderAmount:2835.12,
          //   tradeList:[
          //     {
          //       merOrderNo: '4354535', //商户订单号
          //       payType: 9,  //交易方式 1支付宝 2微信 3云闪付
          //       settAmount:156.12,
          //       payStatus:2,   //1已付款 2已退款
          //       payTime:'2022-06-25 10:23:42',
          //     },
          //     {
          //       merOrderNo: '4354535', //商户订单号
          //       payType: 7,  //交易方式 1支付宝 2微信 3云闪付
          //       settAmount:156.12,
          //       payStatus:2, 
          //       payTime:'2022-06-25 10:23:42',
          //     }
          //   ]
          // }
        ],
        queryParams: {    // 查询参数
          pageNum: 1,
          pageSize: 10,
          payStatus: null,  //支付状态id
          payStatusName: null,  //支付状态id
          shopId: null,   //门店id
          shopName: null, //门店名称
          startTime:null, //开始时间
          endTime: null,  //结束时间
          orderNo: null,  //订单号
          termId: null,  //终端
          termName: null,  //终端名称
        },
        showStore: false,   //是否显示门店列表弹窗
        storeList:[],   //门店列表
        showStatus: false,  //是否显示交易状态弹窗  
        PayStatusList:[
          {
            value:0,
            text:'请选择交易状态'
          },
          {
            value:1,
            text:'处理中'
          },
          {
            value:2,
            text:'下单成功'
          },
          {
            value:3,
            text:'下单失败'
          },
          {
            value:4,
            text:'支付成功'
          },
          {
            value:5,
            text:'支付失败'
          },
          {
            value:6,
            text:'已退款'
          },
        ],
        showTime:false, //是否显示选择时间弹窗
        currentDate: new Date(),  //当前时间
      })

      onMounted(async () => {
        getStoreList(); //获取门店列表
        // getOrderFlowList(); //获取订单流水
        let searchKeyword = localStorage.getItem('searchKeyword');
        if(searchKeyword){
          customFilter(JSON.parse(searchKeyword));
          localStorage.setItem('searchKeyword', '')
        }
      })

      // nextTick(() => {
      //   document.body.setAttribute('style', 'background:#efefef')
      // })

      //获取订单流水分页
      const loadData = () => {      
        const param = {
          pageNum: state.queryParams.pageNum,
          pageSize: state.queryParams.pageSize,
          orderNo: state.queryParams.orderNo,   //订单号
          // termId: state.queryParams.termId,   //终端id
          payStatus: state.queryParams.payStatus&& state.queryParams.payStatus!=0?state.queryParams.payStatus:null,  //支付状态id
          shopId: state.queryParams.shopId,   //门店id
          startTime: state.queryParams.startTime?(state.queryParams.startTime+' 00:00:00'):null, //开始时间
          endTime: state.queryParams.endTime?(state.queryParams.endTime+' 23:59:59'):null,  //结束时间
        }
        getOrderFlowApi(param).then(res => {
          if(res && res.rows){
            const data = res.rows;
            state.list = state.list.concat(data)
            state.totalPage = res.pageCount;
            state.loading = false;
            if (state.queryParams.pageNum >= res.pageCount) state.finished = true
          }
        });
      }
      //加载数据
      const onLoad = () => {
        if (!state.refreshing && state.queryParams.pageNum < state.totalPage) {
          // console.log(state.queryParams.pageNum)
          // console.log(state.totalPage)
          state.queryParams.pageNum = state.queryParams.pageNum + 1
        }
        if (state.refreshing) {
          state.list = [];
          state.refreshing = false;
        }
        loadData();
      }
      //下拉刷新
      const onRefresh = () => {
        state.refreshing = true
        state.finished = false
        state.loading = true
        state.queryParams.pageNum = 1
        onLoad();
      }  

      //获取门店列表
      const getStoreList = () => {
        const param = {
          // startPage: false,          
        }
        getStoreListApi(param).then(res => {
          if(res && res.rows){
            let data = res.rows;
            for(let d of data){
              d.value = d.id;
              d.text = d.shopName;
            }
            state.storeList = data;            
          }          
        }); 
      }
      //选择门店
      const onConfirmStore = (value) => {
        if(value){
          state.queryParams.shopId = value.value;
          state.queryParams.shopName = value.text;
          onRefresh();
          // state.queryParams.pageNum = 1;
          // state.list = [];
          // loadData();
        }
        state.showStore = false;
      }
      //选择交易状态
      const onConfirmStatus = (value) => {
        if(value){
          state.queryParams.payStatus = value.value;
          state.queryParams.payStatusName = value.text;
          onRefresh();
          // state.queryParams.pageNum = 1;
          // state.list = [];
          // loadData();
        }
        state.showStatus = false;
      }

      //选择日期
      const onConfirmsTime = (value) => {
        if(value){
          state.queryParams.startTime = parseTime(value,'{y}-{m}-{d}');
          state.queryParams.endTime = parseTime(value,'{y}-{m}-{d}');
          onRefresh();
          // state.queryParams.pageNum = 1;
          // state.list = [];
          // loadData();
        }
        state.showTime = false;
      }

      //展开收起订单详情
      const toggleDetails = (item, index) => {
        if(item.tradeList && item.tradeList.length>0){
          state.list[index].show = !state.list[index].show;
        }else{
          //如果不存在，则请求详情数据
          const param = {
            day: item.day,
             orderNo: state.queryParams.orderNo,   //订单号
            payStatus:state.queryParams.payStatus && state.queryParams.payStatus!=0?state.queryParams.payStatus:null,  //支付状态id
            shopId:state.queryParams.shopId,   //门店id
          }
          state.list[index].loading = true;
          getFlowDetailApi(param).then(res => {
            if(res && res.data){
              let data = res.data;
              state.list[index].tradeList = data;    
              state.list[index].show = true;
              state.list[index].loading = false;
            }          
          }); 
        }
      }
      
      const goFilter = () => {
        let param = {
          orderNo: state.queryParams.orderNo,
          startTime: state.queryParams.startTime,
          endTime: state.queryParams.endTime,
          shopId: state.queryParams.shopId,
          shopName: state.queryParams.shopName,
          termId: state.queryParams.termId,
          termName: state.queryParams.termName,
        }
        router.push({ path: '/ordersearch', query: { fparam: JSON.stringify(param) }})
      }
      
      const customFilter = (data) => {    
        state.queryParams.PageNumber = 1; 
        state.queryParams.orderNo = data.orderNo;
        state.queryParams.startTime = data.startTime;
        state.queryParams.endTime = data.endTime;
        state.queryParams.currentDate = data.startTime;
        state.queryParams.shopId = data.shopId;
        state.queryParams.shopName = data.shopName;
        state.queryParams.termId = data.termId;
        state.queryParams.termName = data.termName;
        state.list = [];
        onRefresh();
        // loadData();  //获取流水分页
      }

      return {
        ...toRefs(state),
        onLoad,
        onRefresh,
        onConfirmStore,
        onConfirmStatus,
        minDate: new Date(1970, 0, 1),
        onConfirmsTime,
        toggleDetails,
        goFilter,
        customFilter
      }
    },
  }
</script>

<style lang="less" scoped >
  @import '../assets/style/mixin';
    
  .page-box {
    background-color: #efefef;
    font-size: 12px;
    padding-bottom: env(safe-area-inset-bottom);
    min-height: 100vh;
  }
  /* 搜索 */
  .search-box{
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    height: 93px;
    background-color: #fff;
    width: 100%;
  }
  .searchBack{
    height: 93px;
  }
  .order-list-refresh{
    /* height: calc(~"(100vh - 93px)"); */
    min-height: calc(~"(100vh - 93px)");
  }
  .custom-box{
    padding: 15px 15px 17px 15px;
  }
  .s-store{

  }
  .local-icon{
    width: 13px;
    height: 14px;
  }
  .store-name{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-left: 6px;
    margin-right: 7.5px;
  }
  .arrow-icon{
    width: 10px;
    height: 10px;
  }
  .custom-name{
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-left: 6px;
  }
  .screen-icon{
    width: 14px;
    height: 14px;
  }
  .search-in{
    padding: 0 15px 15px;
  }
  .select-box{
    width: 145px;
    height: 27px;
    background: #F5F5F5;
    padding: 0 10px;
  }
  .select-name{
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
  .select-name.active{
    color: #333333;
  }

  /* 订单流水 */
  .order-flow{
    padding-bottom: 15px;
    min-height: 200px;
  }
  .flow-item{
    position: relative;
    margin-top: 12px;
    background-color: #ffffff;
  }
  .flow-summary{
    height: 58px;
    padding: 0 15px;
  }
  .arrow3-icon{
    position: absolute;
    right: 15px;
    top: 26px;
    width: 12px;
    height: 6px;
    transition: .3s;-moz-transition: .3s;-webkit-transition: .3s;-o-transition: .3s;
  }
  .arrow3-icon.open{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
  .flow-detail{
    transition: .3s;-moz-transition: .3s;-webkit-transition: .3s;-o-transition: .3s;
  }
  .summary-box{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .summary-box .summary-time{
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    align-items: center;
  }
  .loading-icon{
    display: inline;
    margin-left: 10px;
  }
  .summary-info{
    margin-top: 8px;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
  }
  .value-item{
    width: 165px;
  }
  .s-value{
    color: #FA3474;
  }

  .detail-item{
    border-top: 1px solid #efefee;
    height: 58px;
    padding: 0 25px;
  }
  .pay-type{

  }
  .pay-icon{
    width: 33px;
    height: 33px;
    margin-right: 9px;
  }
  .type-name{
    font-size: 13px;
    font-weight: 400;
    color: #333333;
  }
  .pay-time{
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-top: 5px;
  }
  .money-info{
    text-align: right;
  }
  .pay-money{
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }
  .pay-status{
    font-size: 12px;
    font-weight: 400;
    color: #0DCC3A;
    margin-top: 5px;
  }
  .pay-fail{
    color: #FF0000;
  }

</style>