
import request from "@/utils/request";


//查询订单流水分页
export function getOrderFlowApi(data) {
  return request({
    url: '/wxapp/mer/flow/list',
    method: 'get',
    params: data
  })
}

//查询订单流水详情
export function getFlowDetailApi(data) {
  return request({
    url: '/wxapp/mer/flow/detail_v2',
    method: 'post',
    params: data
  })
}